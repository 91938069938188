<template>
  <section id="about" class="about section">
    <div class="container">
      <h2 class="section-title">Enclave76: Guardians of America's Legacy</h2>
      <p class="section-subtitle">Preserving the Purity of Our Great Nation</p>
      <div class="about-content">
        <div class="about-text">
          <p>My fellow Americans, this is your President, John Henry Eden. The Enclave is not just an organization; we are the last bastion of true American values and ideals in this ravaged world.</p>
          
          <p>Our mission is clear: to reclaim this great land from the mutations and degenerations that plague it. With advanced technology and unwavering resolve, we will rebuild America as it was always meant to be - pure, strong, and free from the taint of radiation and mutation.</p>
          
          <p><a href="https://discord.gg/gQgtrRKefh" target="_blank" class="fw-bold">Join us</a>, my fellow patriots. Together, we will cleanse the wasteland and usher in a new era of American prosperity. Remember, the Enclave is your government, and your government is you.</p>
        </div>
      </div>
    </div>
    <!-- <div class="discord-header">
      <div class="discord-content">
        <h2 class="section-title">Enclave76 on <span style="color:#3498DB;">Discord</span></h2>
        <p class="section-subtitle">Tune in to the voice of true America. Our channel showcases the Enclave's tireless efforts to restore order and purity to our great nation. Subscribe now and be part of the rebirth of the American dream.</p>
        <a :href="discordChannelUrl" target="_blank" class="btn btn-primary fw-bold" @click="trackEvent('Join us on Discord')">Join us on Discord</a>
      </div>
    </div> -->
  </section>
</template>

<script>
import logoImage from '@/assets/images/logo.webp';

export default {
  name: 'AboutSection',
  data() {
    return {
      logoSrc: logoImage,
    }
  },
  computed: {
    discordChannelUrl() {
      return 'https://discord.gg/gQgtrRKefh'; // Adjust this as needed
    },
  },
  methods: {
    trackEvent(action) {
      if (window.gtag) {
        window.gtag('event', action, {
          'event_category': 'Button',
          'event_label': 'Discord Section'
        });
      } else {
        console.warn('Google Analytics not loaded');
      }
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

#about {
  background: linear-gradient(145deg, #1b2735, #101820); /* Dark MODUS-like gradient */
  color: #00ffcc; /* Neon cyan text color */
  box-shadow: 0px 0px 30px 10px rgba(0, 255, 204, 0.3); /* Glow effect */
  padding: 40px 0;
}

#about .container {
  padding: 24px 0;
}

.section-title, .section-subtitle {
  font-family: 'Agency Regular', sans-serif !important;
  color: #00ffcc; /* Match text color to neon */
  text-shadow: 0px 0px 10px rgba(0, 255, 204, 0.8); /* Text glow */
}

.about-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4rem;
}

.about-text p {
  color: #f6f6f6; /* Ensure readability on dark background */
  font-size: 1.25rem;
  line-height: 1.6;
}

.about-text a {
  color: #00ffcc; /* Keep link color in line with the neon theme */
  text-decoration: underline;
  text-shadow: 0px 0px 5px rgba(0, 255, 204, 0.8); /* Link glow */
}

.about-text a:hover {
  color: #ffcc00; /* Hover state for better user feedback */
  text-shadow: 0px 0px 5px rgba(255, 204, 0, 0.8);
}

.discord-header {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
  background-color: #000;
  background-image: url('@/assets/images/youtube_background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.discord-content {
  max-width: 50%;
  color: #f6f6f6;
  position: relative;
  z-index: 1;
  text-align: right;
  margin-left: auto;
}

.btn-primary {
  background-color: #00ffcc;
  border-color: #00ffcc;
  color: #000;
  padding: 10px 20px;
  font-size: 1rem;
  text-decoration: none;
}

.btn-primary:hover {
  background-color: #ffcc00;
  border-color: #ffcc00;
}
</style>
